import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/icon.png'
import tron from '../assets/images/trx.svg'
import polkadot from '../assets/images/polkadot.svg'
import tezos from '../assets/images/xtz.svg'
import cosmos from '../assets/images/atom.svg'
import cardano from '../assets/images/ada.svg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="STAKENODE.ONE" />
        <div id="wrapper">
          <Header />
          <div id="content">
          <Waypoint
            onEnter={this._handleWaypointEnter}
            onLeave={this._handleWaypointLeave}
          ></Waypoint>
          <Nav sticky={this.state.stickyNav} />

          <div id="main">
            <section id="intro" className="main">
              <div className="spotlight">
                <div className="content">
                  <header className="major">
                    <h2>About Us</h2>
                  </header>
                  <p>
                  We believe in transparency, freedom of information and open infrastructure. 
                  We are a small business staking initiative working to contribute to the global goal of decentralization and open ifrastructure. 
                  We currently offer staking for Cardano but are working on adding other projects. 
                  Our servers are spread across two continents. We have a commitment to uptime and security. 
                  </p>
                </div>
                <span className="image">
                  <img src={pic01} alt="" />
                </span>
              </div>
            </section>

            <section id="first" className="main special">
              <header className="major">
                <h2>Features</h2>
              </header>
              <ul className="features">
                <li>
                  <span className="icon major style1 fa-globe"></span>
                  <h3>Distributed</h3>
                  <p>
                    Our nodes operate on various non AWS rack spaces located throughout the world.
                  </p>
                </li>
                <li>
                  <span className="icon major style3 fa-lock"></span>
                  <h3>Secure</h3>
                  <p>
                    We don’t compromise on security. Our infrastructure is hardened and battle tested.
                  </p>
                </li>
                <li>
                  <span className="icon major style5 fa-clock-o"></span>
                  <h3>Dedicated</h3>
                  <p>
                    Our nodes have 99% uptime and we have 24 hour coverage of our nodes.
                  </p>
                </li>
              </ul>
            </section>

            <section id="second" className="main special">
              <header className="major">
                <h2>Staking</h2>
                <img className="tokenicon" src={cardano} alt="" width="125"/>
                <br />
                <br />
                <p>
                  Currently we offer staking for Cardano as it is the project who's values and approach that most align with our own.
                  <br />                  
                </p>
              </header>
              <footer className="major">
                <ul className="actions">
                  <li>
                    <Link to="/ada" className="button special">
                      Learn More
                    </Link>
                  </li>
                </ul>
              </footer>
            </section>

            <section id="cta" className="main special">
              <header className="major">
                <h2>Future Tokens</h2>
                <p>
                  Check back as we add new nodes for various Proof of Stake projects.
                </p>
              </header>
              <ul className="statistics">
                <li className="style4">
                <span className="icon">
                    <img src={cosmos} alt="" width="50"/>
                  </span>
                  <strong>Cosmos</strong> (ATOM)
                </li>
                <li className="style5">
                <span className="icon">
                    <img src={tezos} alt="" width="50"/>
                  </span>
                  <strong>Tezos</strong> (XTZ)
                </li>
                <li className="style3">
                <span className="icon">
                    <img src={polkadot} alt="" width="50"/>
                  </span>
                  <strong>Polkadot</strong> (DOT)
                </li>
                <li className="style1">
                  <span className="icon">
                    <img src={tron} alt="" width="50"/>
                  </span>
                  <strong>Tron</strong> (TRX)
                </li>
              </ul>
            </section>

          </div>
        </div></div>
      </Layout>
    )
  }
}

export default Index
