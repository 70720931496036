import React from 'react'

import logo from '../assets/images/wall.png';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo">
            <img src={logo} alt="" />     
            <div className="title">STAKENODE.ONE</div>
            <div className="description">Decentralizing the world one node at a time.</div>
        </span>
    </header>
)

export default Header
